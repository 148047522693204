.draggable-table .more-info {
  margin: 0;
  color: rgba(54, 52, 49, 0.6);
}

.draggable-table .more-info ul li {
  padding: 0;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  max-height: 0;
  overflow-y: hidden;
  opacity: 0;
  margin-top: 0;
}

.draggable-table .more-info.extended ul li {
  max-height: 30px;
  opacity: 1;
}

.calendar-table table tr td {
  vertical-align: middle;
}
